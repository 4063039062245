import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { SpinnerComponent } from './modelo/spinner/spinner.component';
import { NavbarComponent } from './pages/navbar/navbar.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AccordionModule } from 'primeng/accordion';
import { SliderModule } from 'primeng/slider';
import { StepsAfiliacionComponent } from './pages/steps-afiliacion/steps-afiliacion.component';
import { ButtonModule } from 'primeng/button';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { KeyFilterModule } from 'primeng/keyfilter';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ChartsModule } from 'ng2-charts';
import { TabViewModule } from 'primeng/tabview';
import { CardModule } from 'primeng/card';
import { EncuestaComponent } from './modelo/encuesta/encuestacomponent';
import { FilaUsuarioComponent } from './modelo/fila-usuario/fila-usuario.component';

@NgModule({
    declarations: [
        SpinnerComponent,
        NavbarComponent,
        StepsAfiliacionComponent,
        EncuestaComponent,
        FilaUsuarioComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        DialogModule,
        ProgressSpinnerModule,
        InputTextModule,
        InputNumberModule,
        InputSwitchModule,
        DropdownModule,
        AccordionModule,
        SliderModule,
        NgxSpinnerModule,
        ButtonModule,
        ToastModule,
        ConfirmDialogModule,
        MenubarModule,
        AutoCompleteModule,
        ChartsModule,
        TableModule,
        TabViewModule,
        CardModule,
        ReactiveFormsModule
    ],
    exports: [
        SpinnerComponent,
        NavbarComponent,
        StepsAfiliacionComponent,
        NgbModule,
        FormsModule,
        DialogModule,
        ProgressSpinnerModule,
        InputTextModule,
        InputNumberModule,
        InputSwitchModule,
        DropdownModule,
        AccordionModule,
        SliderModule,
        NgxSpinnerModule,
        ButtonModule,
        TableModule,
        KeyFilterModule,
        CalendarModule,
        AutoCompleteModule,
        ChartsModule,
        TabViewModule,
        CardModule,
        ReactiveFormsModule,
        EncuestaComponent,
        FilaUsuarioComponent
    ],
    providers: [MessageService, ConfirmationService],
})
export class SharedModule { }
