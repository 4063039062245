import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Persona } from 'src/app/modelo/clasesGenericas/ClasesResponse';

@Injectable({
    providedIn: 'root'
})
export class NotificacionService {

    public server = environment.URL_SERVICE;
    public headers = new HttpHeaders(
        {
            "Content-Type": "application/json",
            "Authorization": "Bearer" + sessionStorage.getItem("token")
        }
    );
    constructor(private http: HttpClient) { }

    // Obtiene todas las notificaciones, leídas o no leídas
    getNotificacion(persona: Persona) {
        const path = this.server + "WSRestNotificacion/getNotificacionByCodUser";
        return this.http.post(path, persona, { headers: this.headers });
    }

    
    leerNotificacion(xCodNotificacion: string) {
        const path = this.server + "WSRestNotificacion/notificacionLeida?xiCodNotifica=" + xCodNotificacion;
        return this.http.post(path, { headers: this.headers });
    }
}
