<app-spinner *ngIf="loading"></app-spinner>
<p-toast position="top-right"></p-toast>
<p-confirmDialog #cd>
    <button type="button" pButton (click)="cd.accept()"></button>
    <button type="button" pButton (click)="cd.reject()"></button>
</p-confirmDialog>

<p-menubar [model]="items">
    <ng-template class="d-flex flex-row justify-content-between align-items-center" pTemplate="start">
        <a class="text-secondary mt-3 mx-4 home" (click)="irHome()">
            <span class="fas fa-home" ></span>
            &nbsp;Inicio
        </a>

        <!-- andysierra temp comment replace-->
        <!-- <ng-container *ngIf="getFotoPerfil() != '' else avatarPorGenero">
            <img [src]="fotoPerfil" height="30" class="p-ml-2 img-circle" (click)="this.informacionUsuario()"/>
        </ng-container> -->
        <ng-container *ngIf="false else avatarPorGenero">
          <img [src]="fotoPerfil" height="30" class="p-ml-2 img-circle" (click)="this.informacionUsuario()"/>
      </ng-container>

        <ng-template #avatarPorGenero>
            <img *ngIf="genero == 'M'" src="./assets/imgUsuarios/avatar_masculino.png" height="30" class="p-ml-2 "
                (click)="this.informacionUsuario()" />
            <img *ngIf="genero == 'F'" src="./assets/imgUsuarios/avatar_femenino.png" height="30" class="p-ml-2 "
                (click)="this.informacionUsuario()" />
        </ng-template>

    </ng-template>

    <ng-template pTemplate="end">
        <span class="p-overlay-badge p-mr-6">
            <a (click)="irNotificaciones()" class="pi pi-bell text-secondary"
                style="font-size: 1.5em; cursor: pointer;"></a>
            <span class="p-badge"> {{ notificacionesSinLeer }} </span>
        </span>
        <!--<input type="text" pInputText placeholder="Search">-->
        <i class="pi pi-sign-out" style="font-size: 1.5rem; cursor: pointer;" (click)="cerrarSesion()"></i>
    </ng-template>
</p-menubar>




<!--<div *ngIf="tipoNavbar == 'session'" class="appNapbar row">
    <div class="col-3">
        <span class="boxImagen spanNavbar mr-5 ml-5" *ngIf="genero=='M'" ><img width="40px" src="./assets/imgUsuarios/avatar_masculino.png" ></span>
        <span class="boxImagen spanNavbar mr-5 ml-5" *ngIf="genero=='F'"><img width="50px" src="./assets/imgUsuarios/avatar_femenino.png" ></span>
        <span class="spanNavbar mr-5">
            <p class="txtNomUsuari">{{nombres}}</p>
            <p class="txtSesion">{{fechaConexion}}</p>
        </span>
    </div>
    <div class="col-3">
        <span class="spanNavbar mr-5">
            <button class="btn cerrar" (click)="informacionUsuario()">
                <i class="fas fa-cog fa-1x"></i>
            </button>


            Ya estaba
              <i class="fas fa-cog fa-2x" routerLink="/asociado/informacionUsuario" routerLinkActive="active"></i>



        </span>
    </div>
    
    <div class="col-3" >
        <span class="spanNavbar mr-5">
            <i class="fa fa-bell" aria-hidden="true"></i>
            <span class="circle">{{notificacionesSinLeer}}</span>
        </span>
    </div>
    
    Ya estaba
    <span class="spanNavbar col-sm-4">
        <button class="btn btn-notf-1 mb-1"
            *ngIf="notificacionesParaLeer[0]!=null">{{notificacionesParaLeer[0]}}</button><br>
        <button class="btn btn-notf-2" *ngIf="notificacionesParaLeer[1]!=null">{{notificacionesParaLeer[1]}}</button>
    </span>
     <span class="spanNavbar col-sm-4">
        <button pButton pRipple type="button" label="Save" icon="pi pi-check" pTooltip="Click to proceed"></button>
   </span>
   Ya




   <div class="col-3">
       <span class="lineaDivisora"></span>
    <span class="spanNavbar ml-1 logout" (click)="logout()">
        <img src="./assets/iconos/logout.png" alt="">
        <p class="txtCerrar">Cerrar</p>
    </span>
   </div>
    
</div>

<div *ngIf="tipoNavbar=='normal'">
    <i class="fas fa-home iconHome"></i>
</div>-->