<div class="contenedor">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="bg"></div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="formulario">
                <div class="boxImagen">
                    <img src="./assets/app/logo.png" class="imgLogo" alt="">
                </div>
                <p class="txtIniciaSesion">Bienvenido a su Cooperativa</p>
                <p class="txtIniciaSesion">Escoge una opción para continuar</p>

                <div class="boxIconos">
                    <div class="boxImg boxImgAso" (click)="navegarRuta('asociado/login')">
                        <img src="./assets/iconos/asociado.png" class="imgIcono">
                    </div>
                    <p class="txtIcono">Asociado</p>
                </div>

                <div class="row mt-5">
                    <div class="col-lg-4 col-md-3 col-sm-3 col-6">
                        <div class="boxImg boxImgIcono" (click)="navegarRuta('empleado/login')">
                            <img src="./assets/iconos/empleado.png" class="imgIcono">
                        </div>
                        <p class="txtIcono">Empleado</p>
                    </div>
                    <div class="col-lg-4 col-md-3 col-sm-3 col-6">
                        <div class="boxImg boxImgIcono">
                        <!-- <div class="boxImg boxImgIcono" (click)="navegarRuta('empresa/login')"> -->
                            <img src="./assets/iconos/empresa.png" class="imgIcono">
                        </div>
                        <p class="txtIcono">Empresa</p>
                    </div>
                    <!--<div class="col-lg-3 col-md-3 col-sm-3 col-6">
                        <div class="boxIconos">
                            <div class="boxImg boxImgIcono" (click)="navegarRuta('admin/login')">
                                <img src="./assets/iconos/administrador.png" class="imgIcono">
                            </div>
                            <p class="txtIcono">Administrador</p>
                        </div>
                    </div>-->
                    <div class="col-lg-4 col-md-3 col-sm-3 col-12">
                        <div class="boxIconos">
                            <div class="boxImg boxImgIcono" (click)="redireccionPQSRF()">
                                <img src="./assets/iconos/administrador.png" class="imgIcono" >
                            </div>
                            <p class="txtIcono">PQRSF</p>
                        </div>
                    </div>
                </div>

                <!-- <div class="boxBtnReg">
                    <button class="btn btnCooperagro" (click)="navegarRuta('asociado/registrarse')">Afiliación</button>
                </div> -->

            </div>
        </div>
    </div>
</div>
