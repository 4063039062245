import { Injectable } from '@angular/core';
import { LoginService } from '../consumoServicios/login.service';

@Injectable({
    providedIn: 'root'
})
export class LoginServiceImp {


    constructor(private loginService: LoginService) {
    }

    loginAsociado(TipoDoi: string, iden: string, password: string) {
        return this.loginService.loginAsociado(TipoDoi, iden, password).toPromise().then(
            (data: any) => {
                return data;
            },
            (error) => {
                return null;
            }
        )
    }
    loginEmpleado(TipoDoi: string, iden: string, password: string) {
        return this.loginService.loginEmpleado(TipoDoi, iden, password).toPromise().then(
            (data: any) => {
                return data;
            },
            (error) => {
                return null;
            }
        )
    }

    loginAdmin(tipoPerfil: string, tipoDoi: string, iden: string, password: string) {
        return this.loginService.loginAdmin(tipoPerfil, tipoDoi, iden, password).toPromise().then(
            (data: any) => {
                return data;
            },
            (error) => {
                return null;
            }
        )
    }

    validarOlvidoClave(tDoc: any, nDoc: any, tPerfil: any) {
        return this.loginService.validarOlvidoClave(tDoc, nDoc, tPerfil).toPromise().then(
            (data: any) => {
                return data;
            },
            (error) => {
                return null;
            }
        )
    }

    generarPassword(tDoc: any, nDoc: any, metodo: any) {
        return this.loginService.generarPassword(tDoc, nDoc, metodo).toPromise().then( 
            (data: any) => {
                return data;
            },
            (error) => {
                return null;
            }
        )
    }

    logout(codigoUser) {
        return this.loginService.logout(codigoUser).toPromise().then(
            (data: any) => {
                return data;
            },
            (error) => {
                return null;
            }
        )
    }

    consPersona(tipoDoi: string, iden: string) {
        return this.loginService.consPersona(tipoDoi, iden).toPromise().then(
            (data: any) => {
                return data;
            },
            (error) => {
                return null;
            }
        )
    }
}
