import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})

export class JwtService {

	private formData: FormData = new FormData();
	public url:string;
	public ws:string;
	public headers: any;
	
	constructor(private _http: HttpClient ) {

		this.headers = new HttpHeaders().set('Content-Type', 'multipart/form-data');
	  }

	saveToken(token: string, expires: string): void {

        // SE GUARDA EL TOKEN EN SESSIONSTORAGE
		sessionStorage.setItem("token", token);
		sessionStorage.setItem("expires", expires);
	}

	getToken(): string {
		let token = sessionStorage.getItem("token");
		if (token != undefined && token != null && token != "") {
			return token;
		} else {
			return "";
		}
	}

	jwtDecode(token: string): string {
		let spliToken = token.split(".");
		let decodeToken = window.atob(spliToken[1]);
		return decodeToken;
	}

	getTokenExpirationDate(token: string): Date {
		const decoded = this.jwtDecode(token);
		const valorToken = JSON.parse(decoded);

		if (valorToken.exp === undefined) {
			return null;
		}
		const date = new Date(0);
		date.setUTCSeconds(valorToken.exp);
		return date;
	}

	isTokenExpired(token?: string): boolean {
		if (!token) {
			token = this.getToken();
		}
		if (!token) {
			return true;
		}

		const date = this.getTokenExpirationDate(token);
		if (date === undefined) {
			return false;
		}
		return !(date.valueOf() > new Date().valueOf());
	}

	isAuthenticated(): boolean {
		const token = sessionStorage.getItem('token');
		console.log("Token ", token);
		return !this.isTokenExpired(token);
	}

	getNotificacionSinleer(): void {
		let cantidad: number;
		let token = this.getToken();

		if (token != "") {

			let spliToken = token.split(".");
			let decodeToken = window.atob(spliToken[1]);
			let valorToken = JSON.parse(decodeToken);
			cantidad = valorToken.valToken.notificacionesSinLeer;
		} 
        else {
            console.log("TOKEN VACÍO!");
			cantidad = -1;
		}
        sessionStorage.setItem("NotificacionesSinLeer", ""+cantidad);
	}

	datosLogin(): string {
		let valorToken: string;
		let token: string = this.getToken();
		if (token != "") {
			let spliToken = token.split(".");
			let decodeToken = window.atob(spliToken[1]);
			valorToken = JSON.parse(decodeToken);
		} else {
			valorToken = "";
		}
		return valorToken;
	}

	getIdenPerson(): string {
		let id: string;
		let token = this.getToken();
		if (token != "") {
			let spliToken = token.split(".");
			let decodeToken = window.atob(spliToken[1]);
			let valorToken = JSON.parse(decodeToken);
			id = valorToken.valToken.idenPersona;
		} else {
			id = "";
		}
		return id;
	}

	getCodPersona(): number {
		let codPerson: number;
		let token = this.getToken();
		if (token != "") {
			let spliToken = token.split(".");
			let decodeToken = window.atob(spliToken[1]);
			let valorToken = JSON.parse(decodeToken);
			codPerson = valorToken.valToken.codPersona;
		} else {
			codPerson = -1;
		}
		return codPerson;
	}

	getTipoIden(): number {
		let tipoIden: number;
		let token = this.getToken();
		if (token != "") {
			let spliToken = token.split(".");
			let decodeToken = window.atob(spliToken[1]);
			let valorToken = JSON.parse(decodeToken);
			tipoIden = valorToken.valToken.tipoIden;
		} else {
			tipoIden = -1;
		}
		return tipoIden;
	}

	getCodUser(): number {
		let tipoIden: number;
		let token = this.getToken();
		if (token != "") {
			let spliToken = token.split(".");
			let decodeToken = window.atob(spliToken[1]);
			let valorToken = JSON.parse(decodeToken);
			tipoIden = valorToken.valToken.codUser;
		} else {
			tipoIden = -1;
		}
		return tipoIden;
	  }
	
	
	  getNombres(): string {
		let fecha: string;
		let token = this.getToken();
		if (token != "") {
		  let spliToken = token.split(".");
		  let decodeToken = window.atob(spliToken[1]);
		  let valorToken = JSON.parse(decodeToken);
		  fecha = valorToken.valToken.nombres;
		} else {
		  fecha = "-1";
		}
		return fecha;
	  }
	
	  getFechaUltimaVez(): string {
		let fecha: string;
		let token = this.getToken();
		if (token != "") {
			let spliToken = token.split(".");
			let decodeToken = window.atob(spliToken[1]);
			let valorToken = JSON.parse(decodeToken);
			fecha = valorToken.valToken.ultimaConexion;
		} else {
			fecha = "-1";
		}
		return fecha;
	}


	getGenero(): string {
		let sGenero: string;
		let token = this.getToken();
		if (token != "") {
			let spliToken = token.split(".");
			let decodeToken = window.atob(spliToken[1]);
			let valorToken = JSON.parse(decodeToken);
			sGenero = valorToken.valToken.genero;
		} else {
			sGenero = "";
		}
		return sGenero;
	}



	getTipoPerfil(): number {
		let tipoPerfil: number;
		let token = this.getToken();
		if (token != "") {
			let spliToken = token.split(".");
			let decodeToken = window.atob(spliToken[1]);
			let valorToken = JSON.parse(decodeToken);
			tipoPerfil = valorToken.valToken.tipoPerfil;
		} else {
			tipoPerfil = -1;
		}
		return tipoPerfil;
	}

	getInformacionUsuario(Codigo) {
		this.formData = new FormData();
		this.formData.append('WS', 'WSInformacionUsuario/getInformacionUsuario');
		this.formData.append('xs_Codigo', Codigo);
		return this._http.post(this.ws, this.formData);
	}

	clearStorage() {
		sessionStorage.clear();
		localStorage.clear();
	}

    // Reducir boilerplate al consultar datos de token:
    splitToken(parte:number, decoded?:boolean) : string {
        let chunk:string = this.getToken().split(".")[parte];
        let resultado:string = null;
        if(chunk != null) {
            resultado = decoded? window.atob(chunk) : chunk;
        }
        return resultado;
    }
}