/**
 *  SERVICE PARA COMUNICACIÓN ENTRE COMPONENTES
 * 
 *  @author Andrés Sierra
 */

import { EventEmitter, Injectable } from "@angular/core";
import { ObserverMensaje } from "src/app/modelo/clases";

@Injectable({
    providedIn: 'root'
})
export class ComunicacionSvc {
    public eventEmitter:EventEmitter<ObserverMensaje>;

    constructor () {
        this.eventEmitter = new EventEmitter<ObserverMensaje>();
    }
}