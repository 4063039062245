import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'empleado',
    loadChildren: () =>
      import('./empleado/empleado.module').then((m) => m.EmpleadoModule),
  },
  {
    path: 'empresa',
    loadChildren: () =>
      import('./empresa/empresa.module').then((m) => m.EmpresaModule),
  },
  {
    path: 'gestor',
    loadChildren: () =>
      import('./gestor/gestor.module').then((m) => m.GestorModule),
  },
  {
    path: 'asociado',
    loadChildren: () =>
      import('./asociado/asociado.module').then((m) => m.AsociadoModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'pqrsf',
    loadChildren: () => import('./pqrf/pqrf.module').then((m) => m.PQRFModule),
  },
  {
    path:'simulador-credito',
    loadChildren: () => import('./simulador-credito/simulador-credito.module').then( m => m.SimuladorCreditoModule)
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/home',
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
export const routingComponents = [];
