import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    public server = environment.URL_SERVICE;
    public headers = new HttpHeaders(
        {
            "Content-Type": "application/json",
            "Authorization": "Bearer" + sessionStorage.getItem("token"),
            "Access-Control-Allow-Origin":"*"
        }
    );

    constructor(private http: HttpClient) { }

    loginAsociado(TipoDoi: string, iden: string, password: string) {
        let xcNavegador = this.obtenerNavegador();
        const path = this.server + "loginUsuario?xiTipoIden=" + TipoDoi + "&xcIdentificacion=" + iden + "&sTipoTransaccion=1&xcClave=" + password + "&xcNavegador=" + xcNavegador + "&xiTipoCanal=1";
        
        return this.http.get(path);
    }
    
    loginEmpleado(TipoDoi: string, iden: string, password: string) {
        let xcNavegador = this.obtenerNavegador();
        const path = this.server + "loginUsuario?xiTipoIden=" + TipoDoi + "&xcIdentificacion=" + iden + "&sTipoTransaccion=1&xcClave=" + password + "&xcNavegador=" + xcNavegador + "&xiTipoCanal=1";
        return this.http.get(path);
    }

    logout(codigoUser: number) {
        const path = this.server + "CerrarSession?iUsuario=" + codigoUser;
        return this.http.get<any>(path);
    }

    loginAdmin(tipoPerfil: string, TipoDoi: string, iden: string, password: string) {
        let xcNavegador = this.obtenerNavegador();
        const path = this.server + "loginAdmin?iTipoPerfil=" + tipoPerfil + "&xiTipoIden=" + TipoDoi + "&xcIdentificacion=" + iden + "&xcClave=" + password + "&xcNavegador=" + xcNavegador + "&xiTipoCanal=1";
        return this.http.post(path, { headers: this.headers });
    }

    validarOlvidoClave(tDoc: any, nDoc: any, tPerfil: any) {
        let params = tDoc + ';' + nDoc + ';' + tPerfil;
        let encodeParams = window.btoa(params);

        let path = this.server + 'validaOlvidoClave?xsData=' + encodeParams + "&xiTipoCanal=1";

        return this.http.post(path, { headers: this.headers });
    }

    generarPassword(tDoc: any, nDoc: any, metodo: any) {
        let params = tDoc + ';' + nDoc + ';' + metodo;
        let encodeParams = window.btoa(params);
        console.log('Respuesta: '+encodeParams);        
        let path = this.server + "generarNuevaClaveWeb/?xsData=" + encodeParams;
        return this.http.post(path, { headers: this.headers });

    }

    consPersona(tipoDoi: string, iden: string) {
        let path = this.server + "validarPersona?xiTipoIden=" + tipoDoi + "&xcIdentificacion=" + iden;
        return this.http.get(path);
    }

    obtenerNavegador() {
        let ua = navigator.userAgent;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
            return ('iOs');
        }
        else if (/Chrome/i.test(ua)) {
            return ('Chrome');
        }
        else {
            return ('Desktop');
        }
    }

}
