import { Injectable } from '@angular/core';
import { Persona } from 'src/app/modelo/clasesGenericas/ClasesResponse';
import { NotificacionService } from '../consumoServicios/notificacion.service';

@Injectable({
    providedIn: 'root'
})
export class NotificacionServiceImp {

    constructor(private notifiService: NotificacionService) { }

    getNotificacion(persona: Persona) {
        return this.notifiService.getNotificacion(persona);
    }

    leerNotificacion(xCodNotificacion: string) {
        return this.notifiService.leerNotificacion(xCodNotificacion);
    }
}
